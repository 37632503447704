import "./productdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { productColumns } from "../../../datatablesource";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
const ProductDatatable = () => {
  const [products,setProducts] = useState([])

  useEffect(()=>{
    const fetchProducts = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"products")
     
        setProducts(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchProducts()
  },[])


  const handleForYou = async(id) =>{
    try {
      await axios.post(process.env.REACT_APP_API_URL+"foryou/"+id)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  



  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/products/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="addButton"
              onClick={() => handleForYou(params.row.id)}
            >
              Zu Für Sie hinzufügen
            </div>
 
          </div>
        );
      },
    },
  ];
    //search alanı olacak
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Product
        <Link to="/products/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={products}
        columns={productColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default ProductDatatable;
