import "./automatSingle.scss"
import { useEffect,useState } from "react";
import axios from "axios";
import { useLocation,useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import Select from "react-select";
const AutomatSingle = () => {
  const navigate = useNavigate()
  const [automats,setAutomats] = useState([])
  const [updateState,setUpdateState] = useState(-1)
  const location = useLocation()

  const handleEdit = (id)=>{
    setUpdateState(id)
  }

  useEffect(()=>{

    const fetchAutomat = async() =>{
      const automatId = location.pathname.split("/automats/")[1]
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"automats/"+automatId)
        setAutomats(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchAutomat()
  },[location.pathname])

  const handleDeleteAutomat = async(id) =>{
    try {
      await axios.delete(process.env.REACT_APP_API_URL+"automats/"+id)
      navigate("/automats")
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {automats && automats?.map((automat) => (
              updateState === automat.id ? <Edit automat={automat} handleEdit={handleEdit}/> :
                <div className="top" key={automat.id}>
                  <div className="left">
                    <div className="editButton" onClick={()=>handleEdit(automat.id)}>Edit</div>
                    <h1 className="title">Information</h1>
                    <div className="item">
                    
                      <div className="details">
                        <h1 className="itemTitle">Automat Seri NO: {automat.automatSeriNo}</h1>
                        <div className="detailItem">
                        <span className="itemKey">User ID:</span>
                          <span className="itemValue">{automat.userId !== null ? automat.userId :"-"}</span>
                        </div>
                    
                        <div className="detailItem">
                        <span className="itemKey">Name and Surname:</span>
                          <span className="itemValue">{automat.name !== null ? automat.name :"-"} {automat.surname}</span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Automat Brand Name:</span>
                          <span className="itemValue">{automat.brandName}</span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Automat Model Name:</span>
                          <span className="itemValue">{automat.automatModelName}</span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Filter Name:</span>
                          <span className="itemValue">{automat.filterName !== null ? automat.filterName :"-"}</span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Period:</span>
                          <span className="itemValue">{automat.periodName !== null ? automat.periodName :"-"}</span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Filter Changed Date:</span>
                          <span className="itemValue">{automat.filterChangedDate !== null ? automat.filterChangedDate :"-"}</span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className="deleteButton" onClick={() => handleDeleteAutomat(automat?.id)}>DELETE AUTOMAT</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        ))}
      </div>
    </div>
  );
};

const Edit = ({automat,handleEdit})=>{
  const notify = (error) => toast(error);
  const [myAutomat,setMyAutomat] = useState(automat)
  
    // isRent ----------------------------------------
    const isRentList = [{value:1,label:"Satıldı"},{value:2,label:"Kiralandı"}]
    const [isRent,setIsRent] = useState()
    const handleIsRent = (data) =>{
      setIsRent(data)
    }
    //--------------------------------------

    //filters------------------------------------------------
    const [filters,setFilters] = useState([])
    const filtersList = []
    useEffect(()=>{
      const fetchFilters = async() =>{
        try {
          const res = await axios.get(process.env.REACT_APP_API_URL+"filters")
       
          setFilters(res.data)
        } catch (error) {
          notify(error.message)
        }
      }
      fetchFilters()
    },[])
    const [filtersOption, setFiltersOption] = useState({value : myAutomat.filterId,label:myAutomat.filterName});
      // Array of all category options
      filters.map(filter => filtersList.push({value : filter.id,label:filter.filterName}))
           // subcategory
           const handleFilterSelect = (data)=>  {
            setFiltersOption(data);
         }
    //---------------------------------------------------------------

    
    //periods------------------------------------------------
    const [periods,setPeriods] = useState([])
    const periodsList = []
    useEffect(()=>{
      const fetchPeriods = async() =>{
        try {
          const res = await axios.get(process.env.REACT_APP_API_URL+"periods")
       
          setPeriods(res.data)
        } catch (error) {
          notify(error.message)
        }
      }
      fetchPeriods()
    },[])
    const [periodsOption, setPeriodsOption] = useState({value : myAutomat.periodId,label:myAutomat.periodName});
      // Array of all category options
      periods.map(period => periodsList.push({value : period.id,label:period.periodName}))
           // subcategory
           const handlePeriodsSelect = (data)=>  {
            setPeriodsOption(data);
         }
    //---------------------------------------------------------------

  const handleChange = (e) =>{
    setMyAutomat((prev) => ({ ...prev, [e.target.name]: e.target.value }));
   }
   const handleAutomatChanges = async(id) =>{
    try {
      var formDataAutomat = new FormData();
      formDataAutomat.append("automatId",myAutomat.id);
      formDataAutomat.append("userId",myAutomat.userId);
      formDataAutomat.append("filterChangedDate",myAutomat.filterChangedDate);
      formDataAutomat.append("automatFilterId",filtersOption.value)
      formDataAutomat.append("periodId",periodsOption.value)
      formDataAutomat.append("isRented", isRent.label);

      const config = {
        headers:{
            "Content-Type":"application/json"
        }}
  
     var res = await axios.put(process.env.REACT_APP_API_URL+"automats/"+id,formDataAutomat,config)
     if(res.status === 200){
      notify("Success")
      window.location.reload()
     }
    } catch (error) {
      notify(error.message)
    }
   }
return (
<div className="top" key={automat.id}>
<div className="left">
<div className="editButton" onClick={()=>handleEdit(-1)}>Go Back</div>
  <h1 className="title">Information</h1>
  <div className="item">
    <div className="detailsEdit">
     
      <div className="formInput">
        <label>User ID:</label>
        <input className="itemValue" name="userId" value={myAutomat.userId} onChange={(e)=>handleChange(e)}/>
      </div>
      <div className="formInput">
                         <label>Filter</label>
                  <Select
                    options={filtersList}
                    placeholder="Select filter"
                    value={filtersOption}
                    onChange={handleFilterSelect}
                    isSearchable={true}
                 
                  /></div>
                      <div className="formInput">
                 <label>Last Changed Date</label>
                  <input name="filterChangedDate" value={myAutomat.filterChangedDate} onChange={handleChange} type="date" placeholder="Filter last changed date" />
                </div>
                <div className="formInput">
                         <label>Period</label>
                  <Select
                    options={periodsList}
                    placeholder="Select period"
                    value={periodsOption}
                    onChange={handlePeriodsSelect}
                  /></div>
                       <div className="formInput">
                <label>Kiralanma Durumu(*)</label>
              <Select
                options={isRentList}
                placeholder="Select rent status"
                value={isRent}
                onChange={handleIsRent}
                isSearchable={true}
              /></div>
      </div>
      <div className="saveButton" onClick={() => handleAutomatChanges(myAutomat.id)}>Save Changes</div>
    </div>
  </div>
  <ToastContainer />
</div>
)
}
export default AutomatSingle;
