import "./new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AutomatNew = ({ title }) => {
  //requests
  const [file, setFile] = useState("");
  const [automat, setAutomat] = useState({});

  const handleChange = (e) => {
    setAutomat((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  const notify = (error) => toast(error);
  const navigate = useNavigate();

  const handleClick = async (e) => {
 
    e.preventDefault();
    try {
      var formDataAutomat = new FormData();
      if(usersOption){
        formDataAutomat.append("userId",usersOption.value);
      }

      formDataAutomat.append("automatBrandId",brandOption.value);
      formDataAutomat.append("automatModelId",modelOption.value);
      formDataAutomat.append("automatSeriNo",automat.automatSeriNo);
      if(filtersOption){
        formDataAutomat.append("automatFilterId",filtersOption.value);
      }
      if(periodsOption){
        formDataAutomat.append("periodId",periodsOption.value);
      }

      if(automat.filterChangedDate){
        formDataAutomat.append("filterChangedDate",automat.filterChangedDate);
      }
     
      if (file) {
        formDataAutomat.append("automatDocument", file);
      }
      formDataAutomat.append("isBuy", isBuy.label);
      if(isRent){
        formDataAutomat.append("isRented", isRent.label);
      }
 
      const config = {
        headers:{
            "Content-Type":"multipart/form-data"
        }}

        var resAutomat =  await axios.post(process.env.REACT_APP_API_URL+"automats", formDataAutomat,config);
          if(resAutomat.status === 200){
            navigate("/automats")
          }else{
            notify(resAutomat)
          }
        
        } catch (error) {
          notify(error.message)
        }
    }

    // isBuy ----------------------------------------
const isBuyList = [{value:1,label:"Satın alındı"},{value:2,label:"Kiralandı"}]
const [isBuy,setIsBuy] = useState()
const handleIsBuy = (data) =>{
  setIsBuy(data)
}
//--------------------------------------

    // isRent ----------------------------------------
    const isRentList = [{value:1,label:"Satıldı"},{value:2,label:"Kiralandı"},{value:3,label:"Depoda"}]
    const [isRent,setIsRent] = useState()
    const handleIsRent = (data) =>{
      setIsRent(data)
    }
    //--------------------------------------

    //brands------------------------------------------------
    const [brands,setBrands] = useState([])
    const brandsList = []
    useEffect(()=>{
      const fetchBrands = async() =>{
        try {
          const res = await axios.get(process.env.REACT_APP_API_URL+"brands")
       
          setBrands(res.data)
        } catch (error) {
          notify(error.message)
        }
      }
      fetchBrands()
    },[])
    const [brandOption, setBrandOption] = useState();
      // Array of all category options
      brands.map(brand => brandsList.push({value : brand.id,label:brand.brandName}))
           // subcategory
           const handleBrandSelect = (data)=>  {
            setBrandOption(data);
         }
    //---------------------------------------------------------------

        //models------------------------------------------------
        const [models,setModels] = useState([])
        const modelsList = []
        useEffect(()=>{
          const fetchModels = async() =>{
            try {
              const res = await axios.get(process.env.REACT_APP_API_URL+"models")
           
              setModels(res.data)
            } catch (error) {
              notify(error.message)
            }
          }
          fetchModels()
        },[])
        const [modelOption, setModelOption] = useState();
          // Array of all category options
          models.map(model => modelsList.push({value : model.id,label:model.automatModelName}))
               // subcategory
               const handleModelSelect = (data)=>  {
                setModelOption(data);
             }
        //---------------------------------------------------------------


                
                //filters------------------------------------------------
                const [filters,setFilters] = useState([])
                const filtersList = []
                useEffect(()=>{
                  const fetchFilters = async() =>{
                    try {
                      const res = await axios.get(process.env.REACT_APP_API_URL+"filters")
                   
                      setFilters(res.data)
                    } catch (error) {
                      notify(error.message)
                    }
                  }
                  fetchFilters()
                },[])
                const [filtersOption, setFiltersOption] = useState();
                  // Array of all category options
                  filters.map(filter => filtersList.push({value : filter.id,label:filter.filterName}))
                       // subcategory
                       const handleFilterSelect = (data)=>  {
                        setFiltersOption(data);
                     }
                //---------------------------------------------------------------

                
                //periods------------------------------------------------
                const [periods,setPeriods] = useState([])
                const periodsList = []
                useEffect(()=>{
                  const fetchPeriods = async() =>{
                    try {
                      const res = await axios.get(process.env.REACT_APP_API_URL+"periods")
                   
                      setPeriods(res.data)
                    } catch (error) {
                      notify(error.message)
                    }
                  }
                  fetchPeriods()
                },[])
                const [periodsOption, setPeriodsOption] = useState();
                  // Array of all category options
                  periods.map(period => periodsList.push({value : period.id,label:period.periodName}))
                       // subcategory
                       const handlePeriodsSelect = (data)=>  {
                        setPeriodsOption(data);
                     }
                //---------------------------------------------------------------

                       //users------------------------------------------------
                       const [users,setUsers] = useState([])
                       const usersList = []
                       useEffect(()=>{
                         const fetchUsers = async() =>{
                           try {
                             const res = await axios.get(process.env.REACT_APP_API_URL+"users/withoutAutomat")
                          
                             setUsers(res.data)
                           } catch (error) {
                             notify(error.message)
                           }
                         }
                         fetchUsers()
                       },[])
                       const [usersOption, setUsersOption] = useState();
                         // Array of all category options
                         users.map(user => usersList.push({value : user.userId,label:user.name + " " + user.surname}))
                              // subcategory
                              const handleUserSelect = (data)=>  {
                                setUsersOption(data);
                            }
                       //---------------------------------------------------------------
                  

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
   
          <div className="right">
            <form onSubmit={handleClick}>
 
             
            
              <div className="formInput">
                <label htmlFor="file">
                  Automat Document: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  name="automatDocument"
                  onChange={(e) => setFile(e.target.files[0])}
          
                />
                </div>
            
                  <div className="formInput">
                  <label>Automat Brand(*)</label>
                  <Select
                    options={brandsList}
                    placeholder="Select brand"
                    value={brandOption}
                    onChange={handleBrandSelect}
                    isSearchable={true}
                 
                  /></div>
                  <div className="formInput">
                  <label>Model(*)</label>
                  <Select
                    options={modelsList}
                    placeholder="Select model"
                    value={modelOption}
                    onChange={handleModelSelect}
                    isSearchable={true}
                 
                  /></div>
                     <div className="formInput">
                         <label>SERI NO(*)</label>
                    <input name="automatSeriNo" onChange={handleChange} type="number" placeholder="123123" />
                   </div>
                <div className="formInput">
                  
                <label>Satın Alma Durumu(*)</label>
              <Select
                options={isBuyList}
                placeholder="Select buy status"
                value={isBuy}
                onChange={handleIsBuy}
                isSearchable={true}
              /></div>
              <div className="formInput">
                <label>Kiralanma Durumu(*)</label>
              <Select
                options={isRentList}
                placeholder="Select rent status"
                value={isRent}
                onChange={handleIsRent}
                isSearchable={true}
              /></div>
                 <div className="formInput">
                         <label>Filter</label>
                  <Select
                    options={filtersList}
                    placeholder="Select filter"
                    value={filtersOption}
                    onChange={handleFilterSelect}
                    isSearchable={true}
                 
                  /></div>
                      
            
                 
                      <div className="formInput">
                 <label>Changed Date</label>
                  <input name="filterChangedDate" onChange={handleChange} type="date" placeholder="Filter last changed date" />
                </div>
                <div className="formInput">
                         <label>Period</label>
                  <Select
                    options={periodsList}
                    placeholder="Select period"
                    value={periodsOption}
                    onChange={handlePeriodsSelect}
                  /></div>
                  <div className="formInput">
                         <label>User</label>
                  <Select
                    options={usersList}
                    placeholder="Select user"
                    value={usersOption}
                    onChange={handleUserSelect}
                    isSearchable={true}
                 
                  /></div>
                   <button className="addUser">Add automat</button>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomatNew;
