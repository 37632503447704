import "../../orders/orderdatatable/orderdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { filterOrderColumns } from "../../../datatablesource";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
const FilterOrdersTable = () => {
  const navigate = useNavigate()
  const [orders,setFilterOrders] = useState([])
  useEffect(()=>{
    const fetchFilterOrders = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"userautomats")
        setFilterOrders(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchFilterOrders()
  },[])

  const handleComplete = async(id) =>{
    try {
      await axios.put(process.env.REACT_APP_API_URL+"userautomats/" + id)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }


  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => handleComplete(params.row.id)}
            >
              Complete
            </div>
      
          </div>
        );
      },
    },
  ];
  //export orders button olacak
  return (
    <div className="datatable">
    
    <div className="datatableTitle">
       Filter Orders
      </div>

      <DataGrid
        className="datagrid"
        rows={orders}
        columns={filterOrderColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};


export default FilterOrdersTable
