export const productColumns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "productId", headerName: "Product ID", width: 120 },
  {
    field: "title",
    headerName: "Title",
    width: 300,
  },
  {
    field: "desc",
    headerName: "Description",
    width: 160

  },
  {
    field: "cartoonAmount",
    headerName: "Cartoon Amount",
    width: 130
  },
];

export const userColumns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "isAdmin", headerName: "Admin", width: 70 },
  { field: "isActive", headerName: "Active", width: 60 },
  { field: "userId", headerName: "User ID", width: 70 },
  {
    field: "name",
    headerName: "Name",
    width: 100,
  },
  {
    field: "surname",
    headerName: "Surname",
    width: 100,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
  },
  
  {
    field: "branch",
    headerName: "Branch Name",
    width: 120,
  },
  {
    field: "companyName",
    headerName: "Company Name",
    width: 130,
  },
  {
    field: "contactPerson",
    headerName: "Contact Person",
    width: 150,
  },


]

export const forYouColumns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "productId", headerName: "Product ID", width: 120 },
  {
    field: "title",
    headerName: "Title",
    width: 180,
  },
]
export const orderColumns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "orderId", headerName: "Order ID", width: 200 },
  { field: "userId", headerName: "User ID", width: 120 },
  { field: "name", headerName: "User Name", width: 120 },
  { field: "surname", headerName: "Surname", width: 120 },
  { field: "orderDate", headerName: "Order Date", width: 150 },
  {field:"status",headerName:"Status",width:100}
]
export const automatColumns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "userId", headerName: "Customer ID", width: 150 },
  { field: "brandName", headerName: "Automat Brand Name", width: 200 },
  { field: "automatModelName", headerName: "Model Name", width: 150 },
  { field: "automatSeriNo", headerName: "Seri No", width: 150 },

]

export const automatmodelColumns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "automatModelName", headerName: "Automat Model Name", width: 200 },


]

export const automatbrandColumns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "brandName", headerName: "Automat Brand Name", width: 200 },

  
]

export const automatfilterColumns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "filterName", headerName: "Filter Name", width: 200 },
]

export const categoryColumns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "categoryName", headerName: "Category Name", width: 200 },
]

export const subcategoryColumns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "subcategoryName", headerName: "Subcategory Name", width: 200 },
]


export const filterOrderColumns = [
  { field: "id", headerName: "ID", width: 80 },
  { field: "name", headerName: "Name", width: 170 },
  { field: "surname", headerName: "Surname", width: 170 },
  { field: "automatId", headerName: "Automat ID", width: 170 },
  { field: "filterName", headerName: "Filter Name", width: 170 },
  { field: "periodName", headerName: "Period Name", width: 170 },
  { field: "filterChangedDate", headerName: "Filter Changed Date", width: 200 },
]