import "./userdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import {  userColumns } from "../../../datatablesource";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
const UserDatatable = () => {
  const [users,setUsers] = useState([])

  useEffect(()=>{
    const fetchUsers = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"users")
     
        setUsers(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchUsers()
  },[])

  const handleDelete = async(id) =>{
    try {
      await axios.put(process.env.REACT_APP_API_URL+"users/deactivate/"+id)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }
  
  const handleActivate = async(id) =>{
    try {
      await axios.put(process.env.REACT_APP_API_URL+"users/activate/"+id)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }

  

  //search alanı olacak
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/users/${params.row.id}`}  style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            
            <div
              className="deleteButton"
               onClick={() => handleDelete(params.row.id)}
            >
              Deactivate
            </div>
            <div
              className="activeButton"
               onClick={() => handleActivate(params.row.id)}
            >
            Activate
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New User
        <Link to="/users/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={users}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default UserDatatable;
