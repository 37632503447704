import React, { useContext } from 'react'
import "./profile.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'

const Profile = () => {
    const { user } = useContext(AuthContext);
    const authUser =    JSON.parse(user)
    const handleResetUserPass = async(id) =>{
        try {
          await axios.get(process.env.REACT_APP_API_URL+"users/resetPassword/"+id)
          window.location.reload()
        } catch (error) {
          console.log(error)
        }
    
      }
    return (
        <div className="profile">
            <Sidebar/>
            <div className="profileContainer">
                <Navbar/>
                <div className="container container-fluid">
                    <h2 className="mt-5 ml-1">My Profile</h2>
                    <div className="row justify-content-start mt-5 user-info">
                        <div className="col-12 col-md-3">
                            <figure className='avatar avatar-profile'>
                                <img src="/user.png" width={"200px"} alt="user" />
                            </figure>
                        </div>
                        <div className="col-12 col-md-5">
                            <h4>Full Name</h4>
                            <p>{authUser.name} {authUser.surname}</p>
                                          
                            <h4>Email Address</h4>
                            <p>{authUser.email}</p>

                            <h4>Phone No</h4>
                            <p>{authUser.phone}</p>

                            <div
                            className="resetButton"
                            onClick={() => handleResetUserPass(authUser.userId)}
                            >
                            Reset Password
                            </div>
        {/* <div
          className="resetButton"
           onClick={() => handleResetUserPass(authUser.userId)}
        >
          Reset Password
        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
