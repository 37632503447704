import "./automatdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { automatColumns } from "../../../datatablesource";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import {CSVLink} from "react-csv"
const AutomatDatatable = () => {
  const [automats,setAutomats] = useState([])
  const [csv,setCsv] = useState([])
  useEffect(()=>{
    const fetchAutomats = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"automats")
        setAutomats(res.data)
        setCsv(prevCsv => res.data.map(item => ({
          automatSeriNo: item.automatSeriNo,
          name: item.name,
          surname: item.surname,
          userId: item.userId,
          isRented: item.isRented
      })));
      } catch (error) {
        console.log(error)
      }
    }
    fetchAutomats()
  },[])

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/automats/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
     
          </div>
        );
      },
    },
  ];
  const headers = [
    { label: "Automat Seri No", key: "automatSeriNo" },
    { label: "Name", key: "name" },
    { label: "Surname", key: "surname" },
    { label: "User ID", key: "userId" },
    { label: "isRented", key: "isRented" }
  ];
  const data = []
  csv.map(item=> data.push( { automatSeriNo: item.automatSeriNo, name: item.name, surname: item.surname, userId: item.userId, isRented:item.isRented}))
 

    //search alanı olacak
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Automat
        <div style={{display:"flex"}}>
        <div className="link">
          <CSVLink className="csv"  separator=";" headers={headers} filename="orders" data={data}>Export Orders CSV </CSVLink> 
        </div>
        <Link to="/automats/new" className="link">
          Add New
        </Link>
        </div>
      
      </div>
      <DataGrid
        className="datagrid"
        rows={automats}
        columns={automatColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default AutomatDatatable;
