export const userInputs = [
  
    {
      id:1,
      label: "User ID(*)",
      type: "number",
      placeholder: "123123",
      name:"userId"
    },
    {
      id:1,
      label: "IsAdmin",
      type: "number",
      placeholder: "0 or 1 (default is 0)",
      name:"isAdmin"
    },
    {
      id:2,
      label: "Name(*)",
      type: "text",
      placeholder: "john_doe",
      name:"name"
    },  
    {
      id:3,
      label: "Surname(*)",
      type: "text",
      placeholder: "John Doe",
      name:"surname"
    },
    {
      id:4,
      label: "Email(*)",
      type: "mail",
      placeholder: "john_doe@gmail.com",
      name:"email"
    },
    {
      id:5,
      label: "Phone(*)",
      type: "number",
      placeholder: "+1 234 567 89",
      name:"phone"
    },
    {
      id:6,
      label: "Street(*)",
      type: "text",
      placeholder: "Elton St. 216",
      name:"street"
    },
    {
      id:7,
      label: "Post NO(*)",
      type: "number",
      placeholder: "Elton St. 216",
      name:"postNo"
    },
    {
      id:8,
      label: "City(*)",
      type: "text",
      placeholder: "Elton St. 216",
      name:"city"
    },
    {
      id:9,
      label: "Country(*)",
      type: "text",
      placeholder: "Elton St. 216",
      name:"country"
    },
  
    {
      id:10,
      label: "Branch(*)",
      type: "text",
      name:"branch"
    },
    {
      id:11,
      label: "Company Name(*)",
      type: "text",
      name:"companyName"
    }


  
  ];
  
  export const productInputs = [
    {
      id:1,
      label: "Product ID(*)",
      type: "number",
      placeholder: "123123",
      name:"productId"
    },
    {
      id:2,
      label: "Title(*)",
      type: "text",
      placeholder: "Snackki automat",
      name:"title"
    },
    {
      id:4,
      label: "Cartoon Amount(*)",
      type: "number",
      placeholder: "6",
      name:"cartoonAmount"
    },
  ];
  