import "./productSingle.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useEffect,useState } from "react";
import axios from "axios";
import { useLocation,useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ProductSingle = () => {
  const navigate = useNavigate();
  const [products,setProducts] = useState([])
  const [updateState,setUpdateState] = useState(-1)
  const location = useLocation()

  const handleEdit = (id)=>{
    setUpdateState(id)
  }

  useEffect(()=>{

    const fetchProduct = async() =>{
      const productId = location.pathname.split("/products/")[1]
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"products/"+productId)
         setProducts(res.data)


      } catch (error) {
        console.log(error)
      }
    }
    fetchProduct()
  },[location.pathname])

  const handleDelete = async(id) =>{
    try {
      await axios.delete(process.env.REACT_APP_API_URL+"products/"+id)
      navigate("/products")
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        {products && products?.map((product) => (
              updateState === product.id ? <Edit product={product} handleEdit={handleEdit}/> :
                <div className="top" key={product.id}>
                  <div className="left">
                    <div className="editButton" onClick={()=>handleEdit(product.id)}>Edit</div>
                    <h1 className="title">Information</h1>
                    <div className="item">
                      <img
                       src={process.env.REACT_APP_API_URL+`images/${product.image}`}
                        alt=""
                        className="itemImg"
                      />
                      <div className="details">
                        <h1 className="itemTitle">{product.title}</h1>
                        <div className="detailItem">
                          <span className="itemKey">PRODUCT ID:</span>
                          <span className="itemValue">{product.productId}</span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Category:</span>
                          <span className="itemValue">{product.categoryName}</span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Subcategory:</span>
                          <span className="itemValue">{product.subcategoryName}</span>
                        </div>
                    
                        <div className="detailItem">
                        <span className="itemKey">Description:</span>
                          <span className="itemValue">{product.desc}</span>
                        </div>
                        <div className="detailItem">
                        <span className="itemKey">Cartoon Amount:</span>
                          <span className="itemValue">{product.cartoonAmount}</span>
                        </div>
                        <div
                          className="deleteButton"
                          onClick={() => handleDelete(product.id)}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        ))}
      </div>
    </div>
  );
};

const Edit = ({product,handleEdit})=>{
  const [myProduct,setProduct] = useState(product)
  const notify = (error) => toast(error);
  const [categories,setCategories] = useState([])
  const categoryOptionList = []
  const [subcategories,setSubcategories] = useState([])
  const subcategoryOptionList = []
  const [categoryOption, setCategoryOption] = useState({value : myProduct.category,label:myProduct.categoryName});
  const [subcategoryOption, setSubcategoryOption] = useState({value : myProduct.sub_category,label:myProduct.subcategoryName});
     // Array of all category options
     categories.map(category => categoryOptionList.push({value : category.id,label:category.categoryName}))

     // Array of all category options
     subcategories.map(category => subcategoryOptionList.push({value : category.id,label:category.subcategoryName}))
        // category
   const handleCategorySelect = (data)=>  {
    setCategoryOption(data);
 }
    // subcategory
    const handleSubcategorySelect = (data)=>  {
      setSubcategoryOption(data);
 
   }

  useEffect(()=>{
    const fetchCategories = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"categories")
     
        setCategories(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchCategories()
  },[])

  useEffect(()=>{
    const fetchSubcategories = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"subcategories")
     
        setSubcategories(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchSubcategories()
  },[])
 const handleProductChanges = async(id) =>{
  try {

    var formDataProduct = new FormData();
    formDataProduct.append("title",myProduct.title);
    formDataProduct.append("category",categoryOption.value) ;
    formDataProduct.append("subCategory",subcategoryOption.value);
    formDataProduct.append("productId",myProduct.productId);
    formDataProduct.append("desc",myProduct.desc);
    formDataProduct.append("cartoonAmount",myProduct.cartoonAmount);
    const config = {
      headers:{
          "Content-Type":"application/json"
      }}

   var res = await axios.put(process.env.REACT_APP_API_URL+"products/"+id,formDataProduct,config)
   if(res.status === 200){
    notify("Success")
    window.location.reload()
   }
  } catch (error) {
    notify(error)
  }
 }
 const handleChange = (e) =>{
  setProduct((prev) => ({ ...prev, [e.target.name]: e.target.value }));
 }
return (
<div className="top" key={product.id}>
<div className="left">
<div className="editButton" onClick={()=>handleEdit(-1)}>Go Back</div>
  <h1 className="title">Information</h1>
  <div className="item">
    <img
     src={process.env.REACT_APP_API_URL+`images/${product.image}`}
      alt=""
      className="itemImg"
    />
    <div className="detailsEdit">
      <div className="formInput">
        <label>Product Name:</label>
        <input className="itemValue" name="title" value={myProduct.title} onChange={(e)=>handleChange(e)}/>
      </div>
      <div className="formInput">
        <label>Product ID:</label>
        <input className="itemValue" name="productId" value={myProduct.productId} onChange={(e)=>handleChange(e)}/>
      </div>
      <div className="formInput">
      <label>Cartoon Amount:</label>
      <input className="itemValue" name="cartoonAmount" value={myProduct.cartoonAmount} onChange={(e)=>handleChange(e)}/>
      </div>
      <div className="formInput">
        <label>Category:</label>
        <Select 
                options={categoryOptionList}
                placeholder="Select category"
                value={categoryOption}
                onChange={handleCategorySelect}
                isSearchable={true}
                
              />
      </div>
      <div className="formInput">
        <label>Subcategory:</label>
        <Select 
                options={subcategoryOptionList}
                placeholder="Select subcategory"
                value={subcategoryOption}
                onChange={handleSubcategorySelect}
                isSearchable={true}
                
              />
      </div>
      <div className="formInput">
      <label>Description:</label>
      <textarea className="itemValue" name="desc" rows="4" cols="50" value={myProduct.desc} onChange={(e)=>handleChange(e)}/>
      </div>

    
    </div>
    <div className="saveButton" onClick={() => handleProductChanges(myProduct.id)}> Save Changes</div>
  </div>
</div>
<ToastContainer />
</div>)
}
export default ProductSingle;
