import "./foryoudatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import {  forYouColumns } from "../../../datatablesource";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
const ForYouDatatable = () => {
  const [forYou,setForYou] = useState([])

  useEffect(()=>{
    const fetchForYou = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"foryou")
     
        setForYou(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchForYou()
  },[])

  const handleDelete = async(id) =>{
    try {
      await axios.delete(process.env.REACT_APP_API_URL+"foryou/"+id)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }

  }
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="cellAction">

            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Aus Für Alle löschen
            </div>
          </div>
        );
      },
    },
  ];
    //search alanı olacak
  return (
    <div className="datatable">
      <div className="datatableTitle">
       Für Alle 
   
      </div>
      <DataGrid
        className="datagrid"
        rows={forYou}
        columns={forYouColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default ForYouDatatable;
