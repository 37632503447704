import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import NotFound from "./pages/NotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { productInputs, userInputs} from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import Profile from "./pages/profile/Profile";
import UserSingle from "./pages/users/userSingle/UserSingle"
import UserNew from "./pages/users/usernew/UserNew"
import ProductSingle from "./pages/products/productSingle/ProductSingle"
import ProductNew from "./pages/products/productnew/ProductNew"
import OrderSingle from "./pages/orders/orderSingle/OrderSingle"
import AutomatSingle from "./pages/automats/automatSingle/AutomatSingle"
import AutomatNew from "./pages/automats/automatnew/AutomatNew"
import AutomatBrandNew from "./pages/automatbrands/automatbrandnew/AutomatBrandNew";
import AutomatModelNew from "./pages/automatmodels/automatmodelsnew/AutomatModelNew";
import AutomatFilterNew from "./pages/automatfilters/automatfiltersnew/AutomatFilterNew";
import CategoriesNew from "./pages/categories/categoriesnew/CategoriesNew";
import SubcategoriesNew from "./pages/subcategories/subcategoriesnew/SubcategoriesNew";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { user } = useContext(AuthContext);
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
    
            <Route path="/"> 
                  <Route index element={ user ? <Home /> : <Login />} />
           
                      <Route path="users">
                          <Route index element={user ? <List type="user"/> : <Login />} />
                          <Route path=":userId" element={user ?  <UserSingle/> : <Login />} />
                          <Route
                            path="new"
                            element={user ? <UserNew inputs={userInputs} title="Add New User" /> : <Login />}
                          />
                      </Route>
                      <Route path="categories">
                        <Route index element={user ? <List type="categories" />: <Login />} />
                     
                        <Route
                          path="new"
                          element={user ? <CategoriesNew title="Add New Category" />: <Login />}
                        />
                      </Route>
                      <Route path="subcategories">
                        <Route index element={user ? <List type="subcategories" />: <Login />} />
                     
                        <Route
                          path="new"
                          element={user ? <SubcategoriesNew title="Add New Subcategory" />: <Login />}
                        />
                      </Route>
                      <Route path="products">
                        <Route index element={user ?<List type="product" />: <Login />} />
                        <Route path=":productId" element={user ?<ProductSingle />: <Login />} />
                        <Route
                          path="new"
                          element={user ? <ProductNew inputs={productInputs} title="Add New Product" />: <Login />}
                        />
                      </Route>
                      <Route path="orders">
                        <Route index element={user ?<List type="order" />: <Login />} />
                        <Route path=":orderId" element={user ?<OrderSingle/>: <Login />} />
                      </Route>
                      <Route path="foryou">
                        <Route index element={user ?<List type="foryou" />: <Login />} />
                      </Route>
                      <Route path="automats">
                        <Route index element={user ? <List type="automat" />: <Login />} />
                        <Route path=":automatId" element={user ?<AutomatSingle />: <Login />} />
                        <Route
                          path="new"
                          element={user ? <AutomatNew title="Add New Automat" />: <Login />}
                        />
                      </Route>
                      <Route path="automatenbrand">
                        <Route index element={user ? <List type="automatenbrand" />: <Login />} />
                     
                        <Route
                          path="new"
                          element={user ? <AutomatBrandNew title="Add New Automat Brand" />: <Login />}
                        />
                      </Route>
                      <Route path="automatenmodel">
                        <Route index element={user ? <List type="automatenmodel" />: <Login />} />
                
                        <Route
                          path="new"
                          element={user ? <AutomatModelNew title="Add New Automat Model" />: <Login />}
                        />
                      </Route>
                      <Route path="automatenfilter">
                        <Route index element={user ? <List type="automatenfilter" />: <Login />} />
                
                        <Route
                          path="new"
                          element={user ? <AutomatFilterNew title="Add New Automat Filter" />: <Login />}
                        />
                      </Route>
                      <Route path="filterorders">
                        <Route index element={user ? <List type="filterorders" />: <Login />} />
                      </Route>
                      <Route path="profile">
                        <Route index element={user ?<Profile />: <Login />} />
                      </Route>
                  </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
