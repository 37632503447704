import React, { useContext, useRef, useState } from 'react';
import './login.scss';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha"; 
import { AuthContext } from '../../context/AuthContext';
function Login() {
  const { dispatchUser } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const captchaRef = useRef(null);

  const verifyToken = async (token) => {
    try{
    let response = await axios.post(process.env.REACT_APP_API_URL+"verify-token",{
       
    secret:process.env.REACT_APP_SECRET_KEY,
        token
    },console.log(token));
    return response.data;
    }catch(error){
    console.log("error ",error);
    }
}
  const handleLogin = async (e) => {
    e.preventDefault();
    if (username && password) {
      // let token = captchaRef.current.getValue();
      // if(token){
      //   let valid_token = await verifyToken(token);
      //   if(valid_token.success){
          axios.post(process.env.REACT_APP_API_URL+"login", {
            userId: username,
            password: password
          })
            .then(response => {
           
              if (response.data.message === "success" && response.data.data.isAdmin === 1) {
              dispatchUser({ type: "LOGIN_SUCCESS", payload:JSON.stringify( response.data.data) });
              } else {
                alert("Login fail.");
              }
            })
            .catch(error => {
              alert("An error occurred during login ??.");
            });
        // }else{
        //   console.log("Sorry!! Token invalid");
        // }
        //   }else{
        //     console.log("You must confirm you are not a robot");
        //   }

    } else {
      alert("Please enter username and password");
    }
    }
  return (
    <div className="login">
      <img src='/snackki_logo3.png' style={{width:"150px"}} alt='logo' />
      <br/>
      <form onSubmit={handleLogin} className="form">
        <label>
          Benutzername:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label>
          Passwort:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
         <br/>
        <button type="submit" className="button" >Anmelden</button>
      </form> 
    </div>
  );
}

export default Login;