import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import axios from "axios"
import { useEffect, useState } from "react";
import MyTable from "../../components/table/Table";

const Home = () => {
  const [users,setUsers] = useState(0)
  const [products,setProducts] = useState(0)
  const [orders,setOrders] = useState([])
  const [automats,setAutomats] = useState(0)

  useEffect(()=>{
    const fetchUsers = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"users")
        setUsers(res.data.length)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchProducts = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"products")
        setProducts(res.data.length)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchOrders = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"orders")
        setOrders(res.data)
      } catch (error) {
        console.log(error)
      }
    }
    const fetchAutomats = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"automats")
        setAutomats(res.data.length)
      } catch (error) {
        console.log(error)
      }
    }
    fetchOrders()
    fetchAutomats()
    fetchProducts()
    fetchUsers()
  },[])
  return (
    <div className="home">
      <Sidebar className="sidebar"/>
      <div className="homeContainer">
        <Navbar className="navbar"/>
        <div className="widgets">
          <Widget type="user" amount={users}/>
          <Widget type="product" amount={products}/>
          <Widget type="automat" amount={automats}/>
          <Widget type="order" amount={orders.length}/>
       
         
         
        </div>
        {orders.length !== 0 ?
        (<div className="listContainer">
          <div className="listTitle">Neueste Bestellungen</div>
          <MyTable orders={orders}/>
        </div>) : <div className="noOrder">Sie haben heute keine Bestellung!</div>}
      </div>
    </div>
  );
};

export default Home;
