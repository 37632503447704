import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { AuthContext } from "../../context/AuthContext";
const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const { dispatchUser } = useContext(AuthContext);

  const handleLogout = (e) => {
    dispatchUser({ type: "LOGIN_START"});
  }
  // const mobileMenuOpenFunc = ()=>{
  //   console.log("hi")
  // }
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/">
        <img src="/icon.png" width={"50px"} height={"50px"} alt="icon" />
          {/* <span className="logo">Snackki Admin</span> */}
        </Link>
        {/* <button onClick={mobileMenuOpenFunc} className="menuIcon"> */}
        <button className="menuIcon">
        <ion-icon style={{zoom:"1.5"}} name="menu-outline"></ion-icon>
        </button>
      </div>
   

      <div className="center">
        <ul>
        
          <p className="title">HAUPTSÄCHLICH</p>
          <Link to="/" style={{ textDecoration: "none" }}>
          <li>
            <DashboardIcon className="icon" />
            <span>Armaturenbrett</span>
          </li>
          </Link>
          <p className="title">LISTEN</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Benutzer</span>
            </li>
          </Link>
          <Link to="/categories" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Kategorien</span>
            </li>
          </Link>
          <Link to="/subcategories" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Unterkategorien</span>
            </li>
          </Link>
          <Link to="/products" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Produkte</span>
            </li>
          </Link>
          <Link to="/orders" style={{ textDecoration: "none" }}>
          <li>
            <ShoppingCartOutlinedIcon className="icon" />
            <span>Bestellung</span>
          </li>
          </Link>
          <Link to="/foryou" style={{ textDecoration: "none" }}>
          <li>
            <ShoppingCartOutlinedIcon className="icon" />
            <span>Für Alle</span>
          </li>
          </Link>
          <p className="title">AUTOMATEN</p>
          <Link to="/automats" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Automatenliste</span>
            </li>
          </Link>
          <Link to="/automatenbrand" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Automatenmarke</span>
            </li>
          </Link> 
            <Link to="/automatenmodel" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Automatenmodell</span>
            </li>
          </Link>
          <Link to="/automatenfilter" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Automatenfilter</span>
            </li>
          </Link>
          <Link to="/filterorders" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Bestellungen Filtern</span>
            </li>
          </Link>
        
          <p className="title">BENUTZER</p>
          <Link to="/profile" style={{ textDecoration: "none" }}>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profil</span>
          </li>
          </Link>
          <Link style={{ textDecoration: "none" }}>
          <li onClick={handleLogout} >
            <ExitToAppIcon className="icon" />
            <span  >Ausloggen</span>
          </li>
        
          </Link>
          <p className="title">BILDSCHIRMMODUS</p>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
      
        </ul>
     
      </div>
    
    </div>
  );
};

export default Sidebar;
