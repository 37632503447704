import "../../automats/automatnew/new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SubcategoriesNew = ({ title }) => {
  //requests
  const [subcategory, setSubcategory] = useState({});

  const handleChange = (e) => {
    setSubcategory((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  const notify = (error) => toast(error);
  const navigate = useNavigate();

  const handleClick = async (e) => {
 
    e.preventDefault();
    try {
      var formDataAutomat = new FormData();
      formDataAutomat.append("subcategoryName",subcategory.subcategoryName);
 
      const config = {
        headers:{
          "Content-Type":"application/json"
        }}

    
        var resAutomat =  await axios.post(process.env.REACT_APP_API_URL+"subcategories", formDataAutomat,config);
          if(resAutomat.status === 200){
            navigate("/subcategories")
          }else{
            notify(resAutomat)
          }
        
        } catch (error) {
          notify(error.message)
        }
    }

   
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
   
          <div className="right">
            <form onSubmit={handleClick}>
                  <div className="formInput">
                     <label>Subcategory Name</label>
                    <input name="subcategoryName" onChange={handleChange} type="text" placeholder="New subcategory" />
                   </div>
                 
                   <button className="addUser">Add subcategory</button>
            </form>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubcategoriesNew
