import "./new.scss";
import Sidebar from "../../../components/sidebar/Sidebar";
import Navbar from "../../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserNew = ({ inputs, title }) => {
    //requests
    const [user, setUser] = useState({});
  
    const handleChange = (e) => {
      setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    const notify = (error) => toast(error);
    const navigate = useNavigate();
  
    const handleClick = async (e) => {
      e.preventDefault()
      try {
      var formDataUser = new FormData();
      formDataUser.append("userId", user.userId);
      formDataUser.append("isAdmin", user.isAdmin ? user.isAdmin  : 0);
      formDataUser.append("name", user.name);
      formDataUser.append("surname", user.surname);
      formDataUser.append("email", user.email);
      formDataUser.append("phone", user.phone);
      formDataUser.append("street", user.street);
      formDataUser.append("postNo", user.postNo);
      formDataUser.append("city", user.city);
      formDataUser.append("country", user.country);
      formDataUser.append("branch", user.branch);
      formDataUser.append("contactPerson", contactPersonOption?.label);
      formDataUser.append(
        "productsOpen",
        JSON.stringify(userProductsOption)
        // userProductsOption.map((opt) => opt.value).join(",")
      );
      formDataUser.append("companyName", user.companyName);
      formDataUser.append(
        "paymentType",
        JSON.stringify(paymentOption)
        // paymentOption.map((opt) => opt.value).join(",")
      );

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

        const resUser = await axios.post(
          process.env.REACT_APP_API_URL+"users",
          formDataUser,
          config
        );
        if (resUser.status === 200) {
          navigate("/users");
        } else {
          notify(resUser)
        }
      } catch (error) {
        notify(error.message)
      }
    };


  //payments-------------------------------------------
  const [payments,setPayments] = useState([])
  const paymentList = []
  useEffect(()=>{
    const fetchPaymentTypes = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"payments")
     
        setPayments(res.data)
      } catch (error) {
        notify(error.message)
      }
    }
    fetchPaymentTypes()
  },[])

  const [paymentOption, setPaymentOption] = useState();
   // Array of all payment options
   payments.map(payment =>  paymentList.push({value : payment.id,label:payment.paymentName}))
   // payment
   const handleSelect = (data)=>  {
    setPaymentOption(data);
   }
//----------------------------------------------------------

// contact person ----------------------------------------
const [contactPerson,setContactPerson] = useState([])
const contactPersonList = []
useEffect(()=>{
  const fetchContactPerson = async() =>{
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL+"users/admin")
   
      setContactPerson(res.data)
    } catch (error) {
      notify(error.message)
    }
  }
  fetchContactPerson()
},[])
const [contactPersonOption, setContactPersonOption] = useState();
// Array of all payment options
contactPerson.map(person =>  contactPersonList.push({value : person.id,label:person.name + " " +person.surname}))
const handleContactPerson = (data) =>{
  setContactPersonOption(data)
}
//--------------------------------------

  //products--------------------------------------------------
  const [products,setProducts] = useState([])
  const productList = []
  useEffect(()=>{
    const fetchProducts = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"products")
     
        setProducts(res.data)
      } catch (error) {
        notify(error.message)
      }
    }
    fetchProducts()
  },[])
  const [userProductsOption, setUserProductsOption] = useState();
     //Array of all products
     products.map(product =>  productList.push({value : product.productId,label:product.title}))
        //user products
   const handleSelectProducts = (data)=>  {
    setUserProductsOption(data);
   }
   
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
    
          <div className="right">
            <form onSubmit={handleClick}>
       
             
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input name={input.name} onChange={handleChange} type={input.type} placeholder={input.placeholder} />
                </div>
              ))}
         
              <div className="formInput">
                <label>Contact Person(*)</label>
              <Select
                options={contactPersonList}
                placeholder="Select contact person"
                value={contactPersonOption}
                onChange={handleContactPerson}
                isSearchable={true}
              /></div>
              
                    
            
              <div className="formInput">
                <label>Payment Type(*)</label>
              <Select
                options={paymentList}
                placeholder="Select payment type"
                value={paymentOption}
                onChange={handleSelect}
                isSearchable={true}
                isMulti
              /></div>
          
         
              <div className="formInput">
                 <label>Products(*)</label>
              <Select
                options={productList}
                placeholder="Select products"
                value={userProductsOption}
                onChange={handleSelectProducts}
                isSearchable={true}
                isMulti
              /></div>
                <button className="addUser"> Add New User</button>
            </form>
          
        
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserNew
