import "./orderdatatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { orderColumns } from "../../../datatablesource";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios"
import {CSVLink} from "react-csv"
const OrderDatatable = () => {
  const [orders,setOrders] = useState([])
  const [csv,setCsv] = useState([])
  useEffect(()=>{
    const fetchOrders = async() =>{
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL+"orders")
        setOrders(res.data)
        setCsv(res.data.map(item=>[item.userId,item.name,item.surname,item.productId,item.productAmount]))
      } catch (error) {
        console.log(error)
      }
    }
    fetchOrders()
  },[])



  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/orders/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
      
          </div>
        );
      },
    },
  ];
  //export orders button olacak
  return (
    <div className="datatable">
    
    <div className="datatableTitle">
        Orders
        <div className="link">
          <CSVLink className="csv" filename="orders" data={csv}>Export Orders CSV </CSVLink> 
        </div>
        
      </div>

      <DataGrid
        className="datagrid"
        rows={orders}
        columns={orderColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default OrderDatatable;
